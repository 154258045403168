import React, { lazy, Suspense } from 'react';

import './App.css';
import './photos.css';
import Hero from './sections/1-hero';
import Section2 from './sections/2-section';
import Section3 from './sections/3-section';
import Section4 from './sections/4-section';
import Section5 from './sections/5-section';
import Section6 from './sections/6-section-bg';
import Section7 from './sections/7-section';
import Popup from './sections/popup';
const Section8 = lazy(() => import('./sections/8-section'));
const Section9 = lazy(() => import('./sections/9-section'));
const Section10 = lazy(() => import('./sections/10-section'));
const Section11 = lazy(() => import('./sections/11-section'));
const Section12 = lazy(() => import('./sections/12-section'));
const Section13 = lazy(() => import('./sections/13-section'));
const Section14 = lazy(() => import('./sections/14-section'));
const Section15 = lazy(() => import('./sections/15-section'));
const Section16 = lazy(() => import('./sections/16-section'));
const Section17 = lazy(() => import('./sections/17-section'));
const Contact = lazy(() => import('./sections/contact'));


function App() {
  return (
    <div className="App">
      <Hero />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section11 />
      <Section12 />
      <Section13 />
      <Section14 />
      <Section15 />
      <Section16 />
      <Section17 />
      <Contact/>
      {/* <Popup/> */}
    </div>
  );
}

export default App;
